<template>
  <div class="actions">
    <button
      class="actions__btn"
      :class="{ active: isFavoriteAdded(product.id) }"
      @click.prevent="addToFavorite(product)"
    >
      <img
        class="actions__image"
        src="~~/assets/icons/favorite.svg"
        alt="favorite button"
      />
      <img
        class="actions__image-hover"
        src="~~/assets/icons/favorite-hover.svg"
        alt="favorite add"
      />
      <img
        class="actions__added"
        src="~~/assets/icons/favorite-added.svg"
        alt="favorite added button"
      />
    </button>
    <button
      class="actions__btn"
      :class="{ active: isComparisonAdded(product.id) }"
      @click.prevent="addToComparison(product)"
    >
      <img
        class="actions__image"
        src="~~/assets/icons/comparison.svg"
        alt="comparison button"
      />
      <img
        class="actions__image-hover"
        src="~~/assets/icons/comparison-hover.svg"
        alt="comparison add"
      />
      <img
        class="actions__added"
        src="~~/assets/icons/comparison-added.svg"
        alt="comparison added button"
      />
    </button>
  </div>
</template>

<script setup>
import {
  accountModal,
  useModalStore,
} from "~/modules/shared/components/modals/store/modalStore";
import { useFavoriteProductStore } from "~/store/favoriteProductStore";
import { useUserStore } from "~/store/userStore";
import { useComparisonStore } from "~~/store/comparisonStore";
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
});

onBeforeMount(async () => {
  await comparisonStore.fetch();

  if (null !== userStore.currentUser) {
    await favoriteProductStore.fetch();
  }
});

const comparisonStore = useComparisonStore();
const { getIsProductInList: isComparisonAdded } = storeToRefs(comparisonStore);

const favoriteProductStore = useFavoriteProductStore();
const { getIsProductInList: isFavoriteAdded } =
  storeToRefs(favoriteProductStore);

const userStore = useUserStore();
const { currentUser: user } = storeToRefs(userStore);

const modalStore = useModalStore();

const addToComparison = (product) => {
  if (!isComparisonAdded.value(product.id)) {
    comparisonStore.addItem(product);
  } else {
    comparisonStore.removeItem(product);
  }
};

const addToFavorite = (product) => {
  if (null === user.value) {
    return modalStore.toggleModal(accountModal, { component: "signIn" });
  }

  if (!isFavoriteAdded.value(product.id)) {
    favoriteProductStore.addProduct(product.id);
  } else {
    favoriteProductStore.removeProduct(product.id);
  }
};
</script>

<style lang="scss" scoped>
.actions {
  @include flex-container(column, flex-start, center);
  gap: 8px;

  &__btn {
    @include fixedHW(32px, 32px);

    @include flex-container(row, center, center);

    border-radius: 50%;
    background-color: rgb(255, 255, 255, 0.5);

    transition: box-shadow 0.2s;

    cursor: pointer;

    &:not(.active):hover {
      & .actions__image {
        opacity: 0;
        visibility: hidden;
      }

      & .actions__image-hover {
        opacity: 1;
        visibility: visible;
      }
    }

    &:is(.active):hover,
    &:not(.active):hover {
      box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.15);
    }
  }

  &__image {
    position: absolute;

    transition:
      opacity 0.2s,
      visibility 0.2s;
  }

  &__image-hover {
    position: absolute;

    opacity: 0;
    visibility: hidden;

    transition:
      opacity 0.2s,
      visibility 0.2s;
  }

  &__btn.active {
    .actions__image {
      display: none;
    }

    .actions__added {
      display: block;
    }
  }

  &__added {
    display: none;
  }
}
</style>
